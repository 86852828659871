.header {
  width: 100%;
  height: 80px;
  padding: 15px 70px;
  border-bottom: 2px solid #f3f3f3;
  background: white;
}

.header > nav {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header__logo a {
  color: var(--main, #0d4da7);
  font-size: 40px;
  font-weight: 800;
  text-transform: uppercase;
}

.authList {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.authList a {
  display: inline-flex;
  height: 50px;
  padding: 10px 20px;
  align-items: center;
  border-radius: 5px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.1s ease-in;
  font-family: "Sofia Sans Condensed";
  background: #c5a465;
  color: white;
  border: 1px solid #c5a465;
}

.authList a:hover {
  background: #ae8330;
  border: 1px solid #ae8330;
}
.authList li {
  margin-left: 10px;
}

.authList a {
  text-align: center;
}

.headerNavigation {
    display: flex;
    align-items: center;
}

@media (max-width: 720px) {
  .header {
    padding: 15px;
  }
}

@media screen and (max-width: 360px) {
  .authList a {
    font-size: 10px;
  }
}
