.gallery__container {
    width: 100%;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.gallery__wrapper {
    position: relative;
    width: 100%;
    max-width: 700px;
    height: 100%;
}

.gallery {
    overflow: hidden;
    border-radius: 5px;
}

.gallery__thumbs {
    height: 340px;
    overflow: hidden;
    position: relative;
}

.gallery__thumbs:before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 78px;
    background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0.00) 100%);
    z-index: 2;
}

.thumbsSwiper {
    max-width: 700px;
    height: 100%;
    margin: 0;
    overflow: hidden;
}

.gallery__slide {
    height: 340px;
}

.gallery__slide img {
    width: 100%;
    height: 100%;
    max-height: 340px;
    object-fit: cover;
}

.gallery__slide img,
.gallery__sub_slide img {
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
}

.gallery__sub_slide {
    position: relative;
    cursor: pointer;
    width: 100px;
    max-height: 90px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 10px;
}

.gallery__sub_slide img {
    object-fit: cover;
    height: 100%;
    transition: .2s ease-out;
}

.gallery__sub_slide:hover img {
    transform: scale(1.1);
}

.gallery__navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    z-index: 1000;
    transition: .5s;
    cursor: pointer;
}

.gallery__navigation > img {
    width: 40px;
    height: 40px;
}

.gallery__navigation_prev:hover {
    left: 5px;
}

.gallery__navigation_next:hover {
    right: 5px;
}

.gallery__navigation_prev {
    left: 10px;
}

.gallery__navigation_next {
    right: 10px;
}

@media screen and (max-width: 1280px) {
    .gallery__thumbs:before {
        width: 50px;
        right: 0;
        left: initial;
        background: linear-gradient(270deg, white 0%, rgba(255, 255, 255, 0.00) 100%);
    }

    .gallery__thumbs_small {
        width: 100%;
    }

    .gallery__thumbs,
    .gallery__thumbs:before {
        height: 100px;
    }

    .gallery__sub_slide img {
        height: 100%;
    }
}

@media screen and (max-width: 720px) {
    .gallery__sub_slide {
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 380px) {
    .gallery__thumbs {
        height: 400px;
        width: 100%;
    }

    .gallery__thumbs:before {
        width: 100%;
        height: 45px;
        right: initial;
        left: initial;
        background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0.00) 100%);
    }
}
