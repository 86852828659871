.small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
}

.small .progressBar__wrapper {
    height: 5px !important;
    border-radius: 10px;
    border: none;
    max-width: 265px;
}

.small .progressBar__container {
    background-color: #F3F3F3;
    border-radius: 10px;
}

.small .progressBar__completed {
    border-radius: 10px;
    height: 5px;
}

.small .progressBar__label {
    display: none;
}

.progressBar__wrapper {
    border: solid 1px #E0E3E9;
    width: 100%;
    height: 25px;
    border-radius: 15px;
    overflow: hidden;
}

.progressBar__completed {
    background-color: #C5A465;
    border-radius: 15px;
}

.progressBar__label {
    position: relative;
    bottom: 1px;
    left: -6px;
    margin-left: 10px;
    font-size: 12px;
    color: white;
    background-color: #C5A465;
    padding: 2px;
    border-radius: 50%;
}

@media screen and (max-width: 980px) {
    .small {
        flex-direction: column !important;
        gap: 30px;
    }

    .small .progressBar__maxLabel {
        position: initial;
    }
}
