.layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.layoutGoBack {
  position: absolute;
  top: 100vh;
}

.content {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1240px;
}

.content > * {
  flex: 1;
}
