.gallery__container {
    width: 100%;
    min-height: 285px;
    max-height: 80vh;
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.gallery__wrapper {
    position: relative;
    width: 100%;
    max-width: 700px;
    max-height: 80vh;
}

.gallery {
    height: 100%;
    overflow: hidden;
    border-radius: 5px;
}

.gallery__slide {
    border-radius: 5px;
    border: solid 1px #DEDEDE;
}

.gallery__slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.gallery__slide img {
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
}

.gallery__navigation {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
    z-index: 1000;
    transition: .5s;
    cursor: pointer;
}

.gallery__navigation_prev:hover {
    left: -25px;
}

.gallery__navigation_next:hover {
    right: -25px;
}

.gallery__navigation_prev {
    left: -20px;
}

.gallery__navigation_next {
    right: -20px;
}
