.footer {
    display: block;
    width: 100%;
    font-family: 'Fira Sans Condensed';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
}

.footerWrapper {
    width: 100%;
    max-width: 1240px;
    margin: 30px auto 0;
    border-top: 1px solid #7a7a7a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.footerNavigation {
    display: flex;
    justify-content: center;
    align-content: center;
}

.footerNavigation ul {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.footerWrapper a {
    color: #a2a2a2;
    transition: 0.1s;
}

.footerWrapper:hover {
    color: #c5a465;
}

@media (max-width: 980px) {
    .footerWrapper {
        flex-direction: column;
        align-items: center;
        padding: 15px 0;
    }

    .footerNavigation ul {
        text-align: center;
        flex-direction: column;
    }
}
