:root {
    --main-first: #c5a465;
    --main-first-dark: #0c4470;
    --main-second: #fa7319;
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

ul {
    list-style-type: none;
}
.grecaptcha-error {
    display: none;
}
.grecaptcha-badge {
    display: none;
}
body {
    height: 100%;
    overflow-x: hidden;
    font-family: Gilroy, sans-serif;
    font-size: 1.1em;
}

h1,
h2,
h3 {
    font-family: Gilroy, sans-serif;
}

/* Slider */
.swiper-button-next, .swiper-button-prev {
    display: none !important;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #D9D9D9;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d1b684;
    border-radius: 4px;
    transition: .1s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #c5a465;
}
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #d1b684 #D9D9D9;
}
/* Disable animate.css on small devices */
@media only screen and (max-width: 768px) {
    .animate__animated {
        /*CSS transitions*/
        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;
        /*CSS transforms*/
        -o-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        /*CSS animations*/
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
}

.sliderArrow {
    width: 45px;
    height: 45px;
    background: none;
    border: solid 2px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px;
    margin: 10px;
    transition: 0.3s;
}

.sliderArrow svg {
    fill: rgba(0, 0, 0, 0.5);
    transition: 0.3s;
}

.sliderArrow:hover {
    background-color: white;
}

.sliderArrow:hover svg {
    fill: #232323;
}

/* Disable input number arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* Chat - my message overwrite */
.rce-mbox.rce-mbox-right {
    background-color: var(--main-first);
    color: white;
}

.rce-mbox.rce-mbox-right .rce-mbox-time.non-copiable:before {
    color: white;
}

.rce-mbox.rce-mbox-right .rce-mbox-right-notch {
    fill: var(--main-first);
}

/* Hide recaptcha badge */
.grecaptcha-badge {
    display: none !important;
}


.mui-image-img {
    transition-duration: 0s !important;
    transition-property: none !important;
    animation: none !important;
}

label {
    outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    color: black !important;
}

.simplebar-placeholder {
    display: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}
