.scrollTop {
  position: fixed;
  z-index: 10001;
  right: 25px;
  bottom: 25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--main-first);
  cursor: pointer;
  color: white;
  transition: 0.1s;
}

.scrollTop:hover {
  box-shadow: 0px 0px 5px 1px rgba(75, 75, 75, 0.5);
  background-color: var(--main-second);
}
